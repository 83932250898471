
import { mapState, mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
SwiperCore.use([Navigation, Pagination])
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    mixins: [cms, playGames],
    data() {
        return {
            dragXStart: 0,
            jackpotItemWidth: 280,
            checkInterest: false,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            jackpot: 'cms/jackpot',
        }),
    },
    methods: {
        onIntersect(entries, observer) {
            const interesting = entries[0]?.isIntersecting
            if (interesting) {
                // this.checkInterest = entries[0]?.isIntersecting
                this.checkInterest = true
            }
        },
        onNext() {
            const elParent = document.getElementsByClassName('w-jackpot-slider')
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        getJackpotContentWidth() {
            const client = process.client
            if (client) {
                // Get jackpot content width when size change
                const val = this.$refs?.refJackpot?.clientWidth
                if (val) {
                    // Display only 4 card if more than that should click arrow right icon above
                    this.jackpotItemWidth = Math.ceil(val / 4) - 12
                }
            } else {
                setTimeout(() => {
                    this.getJackpotContentWidth()
                }, 1000)
            }
        },
    },
}
